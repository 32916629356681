import React from "react"
import VisuallyHidden from "@reach/visually-hidden"
import styled, { StyledComponentProps } from "styled-components"
import css from "@styled-system/css"
import { variant, ResponsiveValue } from "styled-system"
import { LoadingIcon } from "@icons/loading"
import { trackButtonClick } from "analytics"

export type ButtonProps = StyledComponentProps<
  "a",
  any, // Theme
  {
    variant?: "primary" | "secondary" | "ghost" | "danger" | "social" | "cta" | "text"
    size?: ResponsiveValue<"large" | "medium" | "small" | "cta">
    fluid?: ResponsiveValue<boolean>
    disabled?: boolean
    loading?: boolean
  },
  ""
>

const buttonVariants = variant({
  variants: {
    cta: {
      color: "white",
      backgroundColor: "primary.300",
      borderColor: "primary.300",
      ":hover": {
        color: "primary.300",
        backgroundColor: "white",
        borderColor: "primary.300",
      },
      'svg[data-name="spinner"] > path': {
        fill: "white",
      },
    },
    primary: {
      color: "white",
      backgroundColor: "gray.text",
      borderColor: "gray.text",
      ":hover": {
        backgroundColor: "gray.700",
        borderColor: "grary.700",
      },
      ":disabled": {
        backgroundColor: "gray.600",
        borderColor: "primary.text",
        cursor: "not-allowed",
      },
      'svg[data-name="spinner"] > path': {
        fill: "white",
      },
    },
    secondary: {
      color: "gray.text",
      borderColor: "gray.text",
      backgroundColor: "transparent",
      ":hover": {
        color: "white",
        backgroundColor: "gray.700",
      },
      ":active": {
        color: "white",
        backgroundColor: "gray.500",
      },
      ":disabled": {
        borderColor: "gray.text",
        backgroundColor: "white",
      },
    },
    ghost: {
      color: "gray.text",
      borderColor: "transparent",
      backgroundColor: "transparent",
      ":hover": {
        backgroundColor: "gray.50",
      },
    },
    danger: {
      color: "white",
      backgroundColor: "error.700",
      borderColor: "error.700",
      ":hover": {
        borderColor: "gray.text",
        backgroundColor: "white",
      },
      ":active": {
        backgroundColor: "error.800",
        borderColor: "error.800",
      },
      ":disabled": {
        backgroundColor: "error.700",
        borderColor: "error.700",
      },
      'svg[data-name="spinner"] > path': {
        fill: "white",
      },
    },
    text: {
      padding: "0px",
      border: "none",
      background: "transparent",
      textDecoration: "underline",
      minWidth: 0,
    },
  },
})

const sizes = variant({
  prop: "size",
  variants: {
    cta: {
      fontSize: 4,
      paddingY: "32px",
      paddingX: "64px",
    },
    large: {
      fontSize: 3,
      paddingY: "24px",
      paddingX: "48px",
    },
    medium: {
      paddingY: "12px",
      paddingX: "16px",
    },
    small: {
      paddingY: "9px",
      paddingX: "16px",
    },
  },
})

const UnstyledButton = React.forwardRef(({ children, ...props }: ButtonProps, forwardRef) => {
  const { loading, disabled, variant, onClick, ...buttonProps } = props
  const supportedLoadingVariants = ["primary", "secondary", "danger"]
  const isLoadingSupported = supportedLoadingVariants.some((supportedVariant) => supportedVariant === variant)

  const as = props.href ? "a" : "button"

  return (
    <StyledButton
      {...buttonProps}
      // @ts-ignore
      ref={forwardRef}
      onClick={(event: any) => {
        if (buttonProps.id) {
          trackButtonClick(buttonProps.id)
        }
        if (onClick) {
          onClick(event)
        }
      }}
      as={as}
      variant={variant}
      disabled={disabled || loading}
    >
      {loading && isLoadingSupported && (
        <>
          <Spinner aria-hidden="true" />
          <VisuallyHidden>Loading</VisuallyHidden>
          <span style={{ visibility: "hidden" }} aria-hidden="true">
            {children}
          </span>
        </>
      )}
      {(!loading || !isLoadingSupported) && children}
    </StyledButton>
  )
})

UnstyledButton.displayName = "UnstyledButton"

export const Button = styled(UnstyledButton)``

Button.displayName = "Button"
Button.defaultProps = {
  variant: "primary",
  size: "medium",
}

function fluid(breakpoint: number) {
  return ({ fluid }: ButtonProps) => {
    if (fluid && typeof fluid === "object") {
      fluid = fluid[breakpoint]
    }

    if (fluid) {
      return "width: 100%;"
    }

    return "width: fit-content;"
  }
}

const StyledButton = styled.button<ButtonProps>`
  min-width: 8em;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 1.4;
  text-align: center;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  position: relative;
  :hover {
    transition: all 0.2s ease-in-out;
  }
  :disabled {
    cursor: auto;
  }
  ${css({
    fontFamily: "base",
    fontWeight: "bold",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "small",
    fontSize: 1,
    ":focus": {
      outlineOffset: "3px",
    },
    svg: {
      marginRight: 2,
    },
  })}
  ${sizes}
  ${buttonVariants}
  ${fluid(0)}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    ${fluid(1)}
    .Box-hash {
      width: 50%;
    }
  }
`

const Spinner = styled(LoadingIcon)`
  width: 16px;
  height: 16px;
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
`
