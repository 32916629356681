import { useCurrentUser } from "react-current-user"
import { Button } from "@ui/button"
import { Heading } from "@ui/heading"
import { PageBlock } from "@ui/page-block"
import { Stack } from "@ui/stack"

export type CurrentAdminBannerProps = {
  id: string
  email: string
  firstName: string
  lastName: string
}

export const CurrentAdminBanner = ({ email, firstName, lastName }: CurrentAdminBannerProps) => {
  const [user] = useCurrentUser()
  const href = user ? `/admin/users/${user.id}` : `/admin`

  return (
    <PageBlock p="3" bg="gray.100" border="1px solid black">
      <Stack direction={["vertical", "horizontal"]} gap="3" alignItems="center" justifyContent="space-between">
        <Stack gap="2">
          <Heading as="div" level="3" variant="subheading">
            Admin: {firstName} {lastName} ({email})
          </Heading>

          {user && (
            <p>
              You are currently impersonating{" "}
              <strong>
                {user.firstName} {user.lastName} ({user.email})
              </strong>
              .
            </p>
          )}
        </Stack>
        <Button href={href} size="small" variant="secondary" fluid={[true, false]}>
          Go to admin panel
        </Button>
      </Stack>
    </PageBlock>
  )
}
