import Box from "@ui/box"
import Button from "@ui/button"
import { Heading } from "@ui/heading"
import Stack from "@ui/stack"
import { rewardsPath } from "@app/routes"
import { useRouter } from "next/router"
import Link from "next/link"

export const EarnRewards = () => {
  const router = useRouter()

  if (router.asPath === rewardsPath) {
    return null
  }

  return (
    <Box padding="4" bg="primary.100">
      <Stack gap="3">
        <Heading as="h2" level="3">
          Earn{" "}
          <strong>
            <em>$50</em>
          </strong>{" "}
          today!
        </Heading>
        <Link href={rewardsPath} passHref>
          <Button id="earn-50-today" variant="secondary" fluid>
            Invite a friend
          </Button>
        </Link>
      </Stack>
    </Box>
  )
}
