import styled from "styled-components"
import {
  display,
  DisplayProps,
  backgroundColor,
  BackgroundColorProps,
  border,
  BorderProps,
  color,
  ColorProps,
  height,
  HeightProps,
  PaddingProps,
  minHeight,
  padding,
  MinHeightProps,
  width,
  WidthProps,
  maxWidth,
  MaxWidthProps,
} from "styled-system"

export type BoxProps = BorderProps &
  BackgroundColorProps &
  ColorProps &
  HeightProps &
  MaxWidthProps &
  MinHeightProps &
  PaddingProps &
  WidthProps &
  DisplayProps

export const Box = styled.div<BoxProps>`
  ${backgroundColor}
  ${height}
  ${minHeight}
  ${width}
  ${maxWidth}
  ${border}
  ${color}
  ${padding}
  ${display}
`

Box.displayName = "Box"

export default Box
