export const homepagePath = "/"

// Auth
export const loginPath = "/login"
export const signupPath = "/signup"
export const passwordEditPath = "/users/password/edit"
export const passwordResetPath = "/users/password/reset"

// Employees
export const employeesPath = "/employees"
export const employeesAddPath = "/employees/add"
export const employeesPayrollRegistrationPath = (clientEmployeeId: string) =>
  `/employees/${clientEmployeeId}/payroll-registration`

// Users
export const userAccountTypePath = "/users/account-type"
export const userConfirmationPath = "/users/confirmation"
export const userEditPath = "/users/edit"

// Misc.
export const rewardsPath = "/rewards"
export const termsOfServicePath = "/terms-of-service"
export const privacyPolicyPath = "https://canadiannanny.ca/privacy"
