import styled from "styled-components"

export const LoadingIcon = styled((props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-name="spinner"
    {...props}
  >
    <path
      d="M16 32C7.16522 32 0 24.8348 0 16H4.17391C4.17391 22.5391 9.46087 27.8261 16 27.8261C22.5391 27.8261 27.8261 22.5391 27.8261 16C27.8261 9.46087 22.5391 4.17391 16 4.17391V0C24.8348 0 32 7.16522 32 16C32 24.8348 24.8348 32 16 32Z"
      fill="#3B3B3E"
    />
  </svg>
))`
  animation: rotation 1s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`
