import * as analytics from "analytics"

export const logout = () => {
  return fetch("/users/sign_out", {
    method: "delete",
    credentials: "same-origin",
  }).then((res) => {
    if (res.status === 204) {
      analytics.reset()
      window.location.pathname = "/login"
    }
  })
}
