import styled from "styled-components"
import css from "@styled-system/css"
import { color, ColorProps, variant, ResponsiveValue } from "styled-system"
import theme from "./theme"

export type HeadingProps = {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | "div" | "legend"
  level: ResponsiveValue<"1" | "2" | "3" | "4" | "5" | 1 | 2 | 3 | 4 | 5 | "m">
  variant?: "marketing" | "subheading"
} & ColorProps

export const Heading = styled.h1<HeadingProps>`
  ${css({
    fontFamily: "brand",
    color: "gray.heading",
    em: {
      textDecoration: "underline",
      textDecorationColor: theme.colors.primary["300"],
      fontStyle: "normal",
    },
  })}
  ${variant({
    variants: {
      marketing: {
        color: "primary.300",
      },
      subheading: {
        fontFamily: "base",
        fontWeight: "bold",
      },
    },
  })}
  ${variant({
    prop: "level",
    variants: {
      m: { fontSize: 6 },
      1: { fontSize: 5 },
      2: { fontSize: 4 },
      3: { fontSize: 3 },
      4: { fontSize: 2 },
    },
  })}
  ${color}
`

Heading.displayName = "Heading"
