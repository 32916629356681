import React from "react"
import ReachAlert from "@reach/alert"
import styled, { StyledComponentProps } from "styled-components"
import { variant } from "styled-system"
import css from "@styled-system/css"
import { Stack } from "@ui/stack"
import { Heading } from "@ui/heading"

const purpose = variant<Record<string, unknown>, Exclude<AlertProps["variant"], undefined>, "variant">({
  variants: {
    success: {
      bg: "success.100",
      borderColor: "success.700",
    },
    warning: {
      bg: "warning.100",
      borderColor: "warning.300",
    },
    error: {
      bg: "error.100",
      borderColor: "error.700",
    },
  },
})

export type AlertProps = StyledComponentProps<
  typeof ReachAlert,
  any,
  {
    title?: string
    variant?: "success" | "warning" | "error"
    onClose?(): void
    rounded?: boolean
    boxShadow?: string
    outline?: boolean
  },
  ""
>

export const Alert = styled(({ title, onClose, ...props }: AlertProps) => {
  return (
    <AlertContainer {...props}>
      <Stack gap="2" alignItems="flex-start">
        {title && (
          <Heading as="span" level="4" variant="subheading" style={{ flexShrink: 0 }}>
            {title}
          </Heading>
        )}
        {props.children && (
          <Stack direction="vertical" gap="1">
            {props.children}
          </Stack>
        )}
      </Stack>
    </AlertContainer>
  )
})``

const AlertContainer = styled(ReachAlert).withConfig({
  shouldForwardProp: (prop) => !["rounded", "variant", "boxShadow", "outline"].includes(prop as string),
})<AlertProps>`
  pointer-events: all;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex: row;
  align-items: center;
  ${css({
    color: "gray.text",
    lineHeight: "1.25",
    fontSize: 1,
    paddingX: 4,
    paddingY: 3,
    svg: {
      alignSelf: "flex-start",
      flexGrow: 0,
    },
    div: {
      flexGrow: 1,
    },
    a: {
      fontWeight: "bold",
      cursor: "pointer",
      color: "gray.text",
    },
  })}
  ${(props) =>
    css({
      borderRadius: props.rounded ? "small" : "none",
      boxShadow: props.boxShadow,
      borderWidth: props.outline ? "1px" : 0,
      borderStyle: props.outline ? "solid" : "none",
    })}
  > * + * {
    ${css({ ml: 3 })}
  }
  ${purpose}
`

Alert.displayName = "Alert"
Alert.defaultProps = {
  variant: "error",
}
