import { Box, BoxProps } from "@ui/box"
import styled from "styled-components"
import css from "@styled-system/css"
import { system, ResponsiveValue, flexbox, FlexboxProps, MaxWidthProps } from "styled-system"

export type Direction = "vertical" | "horizontal"

export type StackProps = BoxProps &
  FlexboxProps & {
    gap?: ResponsiveValue<number | string>
    direction?: ResponsiveValue<Direction>
    itemsMaxWidth?: MaxWidthProps["maxWidth"]
  }

export const Stack = styled(Box)<StackProps>`
  display: flex;
  ${system({
    direction: {
      property: "flexDirection",
      defaultScale: {
        vertical: "column",
        horizontal: "row",
      },
    },
    gap: {
      property: "gap",
      scale: "space",
    },
  })}
  ${flexbox}
  ${(props) => {
    if (props.itemsMaxWidth) {
      return css({
        "> *": {
          maxWidth: props.itemsMaxWidth,
        },
      })
    }
  }}
`

Stack.displayName = "Stack"

Stack.defaultProps = {
  direction: "vertical",
}

export default Stack
