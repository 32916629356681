import { Alert } from "@ui/alert"
import { userConfirmationPath } from "@app/routes"

export const VerifyEmailBanner = () => (
  <Alert title="Please check your email" variant="warning">
    <p>We need to verify your email address before you get started, so we've sent you some instructions in an email.</p>
    <p>
      Didn't get the email?{" "}
      <a href={userConfirmationPath} rel="noreferrer noopener">
        Request another email
      </a>
    </p>
  </Alert>
)
