import css from "@styled-system/css"
import styled from "styled-components"
import { Stack } from "@ui/stack"

export type PageBlockProps = {
  maxWidth?: string
}

export const PageBlock = styled(Stack).attrs({
  alignItems: "center",
})<PageBlockProps>`
  ${css({
    paddingX: 5,
    "> * ": {
      width: "100%",
      maxWidth: "page.max",
    },
  })}
  ${(props) =>
    props.maxWidth &&
    css({
      "> * ": {
        maxWidth: props.maxWidth,
      },
    })}
`

PageBlock.displayName = "PageBlock"
PageBlock.defaultProps = {
  gap: "6",
}
